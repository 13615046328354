import React, { Component } from "react";
import logo from "assets/images/icons/logo-colored.png";
import Testimony from "components/common/testimony.jsx";
import Contact from "components/common/contact.jsx";
import GettingStarted from "components/common/gettingStarted.jsx";


class CorporationTax extends Component {
    
    state = {};

    render() {
        
        return (
            <>

                <div className="bg-black relative">
                    <div className="h-36 container flex items-center relative z-20">
                        <div className="w-full text-white text-center text-3xl font-semibold">
                            Management Accounting
                        </div>
                    </div>
                    <div className="w-full h-full opacity-40 img-bg3 absolute inset-0 z-10"></div>
                </div>


                <div className="container md:flex space-y-6 md:space-y-0 py-20">
                    <div className="md:w-1/2 md:flex">
                        <div className="md:my-auto">
                            <div className="text-lg md:text-2xl font-semibold uppercase">
                                What is
                            </div>
                            <div className="text-3xl md:text-5xl text-tegdaffy-red font-semibold">
                                Management
                                <br />
                                Accounting?
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2">
                        <div className="text-xl leading-10">
                            Managerial accounting is the practice of identifying, analysing, interpreting and communicating financial information to managers for the pursuit of an organization's goals. Management accounting varies from financial accounting because the intended purpose is to assist internal members of a company in making well-informed business decisions
                        </div>
                    </div>
                </div>
                

                <div className="py-20 bg-gray-200">
                    <div className="container md:flex space-y-6 md:space-y-0">
                        <div className="md:w-1/2 md:flex">
                            <div className="md:my-auto">
                                <div className="px-5 py-3 inline-block bg-white rounded-lg">
                                    <img src={logo} alt="Tegdaffy Accountants" className="object-contain" />
                                </div>
                                <div className="mt-4 text-3xl md:text-4xl font-semibold uppercase">
                                    How can Tegdaffy help me with my management accounting?
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2">
                            <div className="text-xl leading-10">
                                <ul className="list-disc ml-5">
                                    <li>
                                        Bring clarity to your management accounts
                                    </li>
                                    <li>
                                        Identify key financial indicators 
                                    </li>
                                    <li>
                                        Analyse performance against targets 
                                    </li>
                                    <li>
                                        Identify opportunities for internal savings 
                                    </li>
                                    <li>
                                        Help you monitor your business through a large period of growth 
                                    </li>
                                    <li>
                                        Develop valuations for exit strategies 
                                    </li>
                                    <li>
                                        Help show full transparency to potential investors or buyers 
                                    </li>
                                    <li>
                                        Provide with a broad understanding of your business’ financial wealth
                                    </li>
                                    <li>
                                        Provide advice based off top level analysis to address your concerns, improve business growth and increase profitability
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Testimony />

                <Contact />

                <GettingStarted />


            </>
        );
    }
}

export default CorporationTax;
