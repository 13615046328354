import React, { Component } from "react";
import Testimony from "components/common/testimony.jsx";
import Contact from "components/common/contact.jsx";
import GettingStarted from "components/common/gettingStarted.jsx";


class CorporationTax extends Component {
    
    state = {};

    render() {
        
        return (
            <>

                <div className="bg-black relative">
                    <div className="h-36 container flex items-center relative z-20">
                        <div className="w-full text-white text-center text-3xl font-semibold">
                            Business Plan
                        </div>
                    </div>
                    <div className="w-full h-full opacity-40 img-bg3 absolute inset-0 z-10"></div>
                </div>



                <div className="container md:flex md:space-x-10 space-y-6 md:space-y-0 py-20">
                    <div className="md:w-1/2 md:flex">
                        <div className="md:my-auto">
                            <div className="text-lg md:text-2xl font-semibold uppercase">
                                What is
                            </div>
                            <div className="text-3xl md:text-5xl text-tegdaffy-red font-semibold">
                                Business Planning and Advice?
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2">
                        <div className="text-xl leading-10">
                            This document sets out the goals of the business and
                            how to achieve them
                            <br />
                            <br />
                            We work closely and cooperatively with your business to generate a business plan that can be used to secure a business loan for the purposes of expansion , diversification and most importantly for strategy decision making.
                            <br />
                            <br />
                            As an experienced consultancy firm that has worked with firms from different industry we will use our expertise and knowledge to support, and guide your business.
                        </div>
                    </div>
                </div>
                
                
                <Testimony />

                <Contact />

                <GettingStarted />

            </>
        );
    }
}

export default CorporationTax;
