import React from 'react';

const NullLayout = (props) => {
    return ( 
        <div>
            {props.children}
        </div>
     );
}
 
export default NullLayout;