import React, { Component } from "react";
import Testimony from "components/common/testimony.jsx";
import Contact from "components/common/contact.jsx";
import GettingStarted from "components/common/gettingStarted.jsx";


class CorporationTax extends Component {
    
    state = {};

    render() {
        
        return (
            <>

                <div className="bg-black relative">
                    <div className="h-36 container flex items-center relative z-20">
                        <div className="w-full text-white text-center text-3xl font-semibold">
                            Financial Accounts
                        </div>
                    </div>
                    <div className="w-full h-full opacity-40 img-bg3 absolute inset-0 z-10"></div>
                </div>



                <div className="container md:flex md:space-x-10 space-y-6 md:space-y-0 py-20">
                    <div className="md:w-1/2 md:flex">
                        <div className="md:my-auto">
                            <div className="text-lg md:text-2xl font-semibold uppercase">
                                What is
                            </div>
                            <div className="text-3xl md:text-5xl text-tegdaffy-red font-semibold">
                                Financial Accounts (Statutory)?
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2">
                        <div className="text-xl leading-10">
                            We know and appreciate the stress and level of anxiety HMRC investigation can generate but the good news is that we are here to help you and your business each step of the way.
                            <br />
                            <br />
                            Our tax specialist will support you in the following areas
                            <ul>
                                <li>
                                    Tax enquiries – compliance checks
                                </li>
                                <li>
                                    HMRC penalty mitigation
                                </li>
                                <li>
                                    Mediation
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                
                <Testimony />

                <Contact />

                <GettingStarted />

            </>
        );
    }
}

export default CorporationTax;
