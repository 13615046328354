import React, { Component } from "react";
import { Slide } from 'react-slideshow-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import bg2 from "assets/images/bgs/bg-2.jpg";
import bg3 from "assets/images/bgs/bg-3.jpg";

const slideshowProperties = {
    duration: 3000,
    transitionDuration: 300,
    pauseOnHover: true,
    indicators: false,
    autoplay: true,
    infinite: true,
    canSwipe: true,
    prevArrow: <FontAwesomeIcon icon="angle-left" className="cursor-pointer w-10 h-20 block text-white" />,
    nextArrow: <FontAwesomeIcon icon="angle-right" className="cursor-pointer w-10 h-20 block text-white" />,
    slidesToShow: 2,
}

const slideshowPropertiesMobile = {
    ...slideshowProperties,
    slidesToShow: 1,
}



const testimonies = [
    {
        name: 'Rite2Bite',
        content: <div>
            Friendly, knowledgeable staff explained the options available to us. Company set up and corporation tax at a fixed price. Great follow up communications, speedy service.
        </div>
    },
    {
        name: 'Leterna',
        content: <div>
            TegDaffy sorted my corporation tax after I fell behind. They done a great job, very accommodating, great communication and got it done quickly for us so that we didn’t incur further charges. 10/10 service. Would recommend.
        </div>
    },
    {
        name: 'Declan',
        content: <div>
            I joined TegDaffy as a client shortly after starting my new business earlier this year. Being a new business owner, I felt totally overwhelmed regarding my accounts and general finances and really wanted to find an Accountant that could help. From my very first meeting at the TegDaffy offfices I felt very confident I had come to the right place. They took the time to clearly explain everything to me and was so helpful with all my questions. I've now been with TegDaffy for almost 6 months and haven't looked back. The service, support and guidance has been second to none. I would highly recommend them to anyone looking for a straight-forward, friendly and affordable accounting partner!
        </div>
    },
    {
        name: 'Nicola',
        content: <div>
            Before finding <span className="whitespace-nowrap">Teg Daffy</span> the thought of personal taxes only brought me stress, from unreliable accountants too headaches as a result trying to do it myself! Ever since I came across <span className="whitespace-nowrap">Teg Daffy</span>, I’ve had nothing but peace of mind. I have loved the great communication, speedy execution, and reliable services.
        </div>
    },
    {
        name: 'David',
        content: <div>
            Fast and well supported service, I just sit back and let <span className="whitespace-nowrap">Teg Daffy</span> do the rest!
        </div>
    },
]


class Testimony extends Component {
    state = {};

    render() {
        return (
            <div>
                
                <div className="pt-10" style={{"backgroundImage":"linear-gradient(to right, #0069a3 , #fc151e)"}}>
                        <div className="container">
                            <div className="text-center text-white text-2xl md:text-4xl xl:text-5xl font-semibold mt-1 md:mt-4 md:space-y-3">
                                <div>
                                    Testimonies
                                </div>
                            </div>
                        </div>
                        <div>

                            <div className="container pt-10 pb-20">

                                <div className="hidden md:block">
                                    <Slide {...slideshowProperties} easing="ease" as="div" className="">

                                        {testimonies.map((testimony, index) =>
                                            
                                            <div key={index} className="h-full mt-10 mx-4 pt-14 px-14 pb-10 rounded-lg bg-white shadow-lg">
                                                <div className="flex">
                                                    <img src={bg3} alt="Solomon Dawudu" className="rounded-full w-16 h-16" />
                                                    <div className="flex flex-col my-auto px-4">
                                                        <div className="text-lg lg:text-xl font-semibold">
                                                            {testimony.name}
                                                        </div>
                                                        <div className="hidden opacity-40 text-black text-base">
                                                            ----
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-6 leading-8">
                                                    {testimony.content}
                                                </div>
                                            </div>
                                            
                                        )}

                                    </Slide>
                                </div>


                                <div className="block md:hidden">
                                    <Slide {...slideshowPropertiesMobile} easing="ease">

                                        {testimonies.map((testimony, index) =>

                                            <div key={index} className="m-4 p-6 rounded-lg bg-white shadow-lg">
                                                <div className="flex">
                                                    <img src={bg3} alt="Solomon Dawudu" className="rounded-full w-16 h-16" />
                                                    <div className="flex flex-col my-auto px-4">
                                                        <div className="text-base font-semibold">
                                                            {testimony.name}
                                                        </div>
                                                        <div className="hidden opacity-40 text-black text-sm">
                                                            ----
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-6 pb-2 leading-8">
                                                    {testimony.content}
                                                </div>
                                            </div>
                                            
                                        )}
                                        
                                    </Slide>
                                </div>

                            </div>
                            

                            {/* 
                            <div className="overflow-hidden">
                                <Testimony />
                            </div>
                            */}


                        </div>
                    </div>

            </div>
        );
    }
}

export default Testimony;
