import React from "react";
import Header from "components/common/header.jsx";
import Footer from "components/common/footer.jsx";

const GeneralLayout = (props) => {
    return (
        <React.Fragment>

            <Header />

            <div style={{"minHeight":"500px"}}>
                {props.children}
            </div>

            <Footer />
            
        </React.Fragment>
    );
};

export default GeneralLayout;
