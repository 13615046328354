// import React from 'react';
// import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Switch, Redirect, withRouter } from 'react-router-dom';

// import store from "store/store";

import GeneralLayout from './components/layouts/generalLayout';
import NullLayout from './components/layouts/nullLayout';
import Error401 from './components/misc/error401';
import Error404 from './components/misc/error404';

import Home from './components/pages/home';
import CorporationTax from './components/pages/corporationTax';
import PersonalTax from './components/pages/personalTax';
import ManagementAccounting from './components/pages/managementAccounting';
import CapitalGainsTax from './components/pages/capitalGainsTax';
import InheritanceTax from './components/pages/inheritanceTax';
import VAT from './components/pages/VAT';
import Payroll from './components/pages/payroll';
import BookKeeping from './components/pages/bookKeeping';
import TaxAdvice from './components/pages/taxAdvice';
import CompanyFormation from './components/pages/companyFormation';
import FinancialAccounts from './components/pages/financialAccounts';
import BusinessPlan from './components/pages/businessPlan';
import HMRCInvestigations from './components/pages/HMRCInvestigations';
import DebtManagementCreditControl from './components/pages/debtManagementCreditControl';

import { library } from '@fortawesome/fontawesome-svg-core';
// import { fab } from '@fortawesome/free-brands-svg-icons';
// import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);



const ScrollToTopComponent = () => {
    const mounted = useRef(false)
    useEffect(() => {
        if (!mounted.current) {
            //componentDidMount
            mounted.current = true
        } else {
            //componentDidUpdate
            window.scrollTo(0, 0)
        }
    })
    return null
}
export const ScrollToTop = withRouter(ScrollToTopComponent)



const RouteLayout = ({ component: Component, layout: Layout, authUser, authRole, allowInactiveUser, ...rest }) => (
    <Route {...rest} render={(props) => {

        // const user = store.getState().auth.userPersist;
        // const user_role = user.data.userType;
        // const user_active = user.data.isActive;
        const user = {
            role: "customer",
            active: true,
        };


        if (authUser) {
            if (authUser === "loggedIn") {
                if (user.loggedIn === true) {
                    if (user.active === true) {
                        if (authRole) {
                            if (authRole.includes(user.role)) {
                                return <Layout>
                                    <Component {...props} />
                                </Layout>
                            }
                            else {
                                // alert("Access denied due to user role");
                                return <Redirect to="/401" />
                            }
                        }
                        else {
                            // alert("no roles required, you only need to be logged in");
                            return <Layout>
                                <Component {...props} />
                            </Layout>
                        }
                    }
                    else {
                        if (allowInactiveUser) {
                            // alert("allow inactive user");
                            return <Layout>
                                <Component {...props} />
                            </Layout>
                        }
                        else {
                            // alert("not yet approved by admin");
                            return <Redirect to="/" />
                        }
                    }
                }
                else {
                    // alert("need to be logged in");
                    return <Redirect to="/login" />
                }
            }
            else if (authUser === "loggedOut") {
                if (user.loggedIn === false) {
                    return <Layout>
                        <Component {...props} />
                    </Layout>
                }
                else {
                    // alert("already logged in; need to be logged out to access");
                    return <Redirect to="/dashboard" />
                }
            }
        }
        else {
            // public page, no auth-yes or auth-no required
            return <Layout>
                <Component {...props} />
            </Layout>
        }
    }} />
)



function App() {
    return (
        <React.Fragment>
            <BrowserRouter>
                <ScrollToTop />
                <Switch>

                    <RouteLayout exact path="/corporation-tax" layout={GeneralLayout} component={CorporationTax} />
                    <RouteLayout exact path="/personal-tax" layout={GeneralLayout} component={PersonalTax} />
                    <RouteLayout exact path="/management-accounting" layout={GeneralLayout} component={ManagementAccounting} />
                    <RouteLayout exact path="/capital-gains" layout={GeneralLayout} component={CapitalGainsTax} />
                    <RouteLayout exact path="/inheritance-tax" layout={GeneralLayout} component={InheritanceTax} />
                    <RouteLayout exact path="/vat" layout={GeneralLayout} component={VAT} />
                    <RouteLayout exact path="/payroll" layout={GeneralLayout} component={Payroll} />
                    <RouteLayout exact path="/book-keeping" layout={GeneralLayout} component={BookKeeping} />
                    <RouteLayout exact path="/tax-advice" layout={GeneralLayout} component={TaxAdvice} />
                    <RouteLayout exact path="/company-formation" layout={GeneralLayout} component={CompanyFormation} />
                    <RouteLayout exact path="/financial-accounts" layout={GeneralLayout} component={FinancialAccounts} />
                    <RouteLayout exact path="/business-plan" layout={GeneralLayout} component={BusinessPlan} />
                    <RouteLayout exact path="/hmrc-investigations" layout={GeneralLayout} component={HMRCInvestigations} />
                    <RouteLayout exact path="/debt-management-credit-control" layout={GeneralLayout} component={DebtManagementCreditControl} />

                    <RouteLayout exact path="/401" layout={NullLayout} component={Error401} />
                    <RouteLayout exact path="/404" layout={NullLayout} component={Error404} />
                    <RouteLayout exact path="/" layout={GeneralLayout} component={Home} />

                    <Redirect to="/404" />

                </Switch>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;