import React, { Component } from "react";
import Testimony from "components/common/testimony.jsx";
import Contact from "components/common/contact.jsx";
import GettingStarted from "components/common/gettingStarted.jsx";


class CorporationTax extends Component {
    
    state = {};

    render() {
        
        return (
            <>

                <div className="bg-black relative">
                    <div className="h-36 container flex items-center relative z-20">
                        <div className="w-full text-white text-center text-3xl font-semibold">
                            Capital Gains Tax
                        </div>
                    </div>
                    <div className="w-full h-full opacity-40 img-bg3 absolute inset-0 z-10"></div>
                </div>



                <div className="container md:flex md:space-x-10 space-y-6 md:space-y-0 py-20">
                    <div className="md:w-1/2 md:flex">
                        <div className="md:my-auto">
                            <div className="text-lg md:text-2xl font-semibold uppercase">
                                What is
                            </div>
                            <div className="text-3xl md:text-5xl text-tegdaffy-red font-semibold">
                                Capital Gains Tax?
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2">
                        <div className="text-xl leading-10">
                            When an asset is disposed above its original value it attracts capital gains due to HMRC.
                            <br />
                            <br />
                            We will ensure that your tax liability is accurately calculated and remitted to HMRC
                        </div>
                    </div>
                </div>
                
                
                <Testimony />

                <Contact />

                <GettingStarted />

            </>
        );
    }
}

export default CorporationTax;
