import React, { Component } from "react";
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import emailjs from '@emailjs/browser';

class Contact extends Component {
    state = {
        contactForm: {
            name: "",
            email: "",
            phoneNumber: "",
            message: "",
        },
        submitFormLoading: false,
    };


    schema = {
        name: Joi.string().required().label("Name").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your name";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        email: Joi.string().email().required().label("Email Address").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your email address";
                        break;
                    case "string.email":
                        err.message = "Please enter your valid email address";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        phoneNumber: Joi.string().required().label("Phone Number").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your phone number";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        message: Joi.string().required().label("Message").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your message";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    }

    
    formChange = (e) => {
        const formValue = {...this.state.contactForm};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({contactForm: formValue});
    }

    
    validate = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(this.state.contactForm, this.schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };


    submitForm = (e) => {
        e.preventDefault();
        
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            this.setState({submitFormLoading: true});
            const contactForm = document.getElementById('contactForm');
            
            const emailjsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
            const emailjsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
            const emailjsUserId = process.env.REACT_APP_EMAILJS_USER_ID;
            
            emailjs.sendForm(emailjsServiceId, emailjsTemplateId, contactForm, emailjsUserId)
            .then((result) => {
                toast.success("Message sent successfully. Thank you for contacting us.");
                // console.log("result", result.text);

                const contactForm = {
                    name: "",
                    email: "",
                    phoneNumber: "",
                    message: "",
                };
                this.setState({submitFormLoading: false, contactForm});

            }, (error) => {
                toast.error("An error occurred while sending your message. Please try again later");
                // console.log("error", error.text);
                this.setState({submitFormLoading: false});
            });

        }
    }


    render() {
        const { contactForm, submitFormLoading } = this.state;

        return (
            <div id="contact">
                <div className="container">
                    <div className="text-center text-2xl md:text-4xl xl:text-5xl font-semibold mt-12 md:space-y-3">
                        <div>
                            Contact Tegdaffy
                        </div>
                    </div>
                </div>
                <div className="p-8">

                    <form onSubmit={this.submitForm} id="contactForm">

                        <div className="sm:max-w-3xl mx-auto mt-4 lg:mt-12 grid sm:grid-cols-2 gap-x-6 gap-y-2 box font-semibold">
                            <div className="flex items-center space-x-4 border-r-2 border-transparent sm:border-gray-200 text-xl sm:text-2xl md:text-3xl">
                                <FontAwesomeIcon icon="phone-square" className="text-tegdaffy-blue" />
                                <div>
                                    <Link to={{pathname:"tel:+442032474747"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="hover:text-tegdaffy-red">+44 20 3247 4747</Link>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4 text-xl sm:text-2xl md:text-3xl">
                                <FontAwesomeIcon icon="envelope" className="text-tegdaffy-blue" />
                                <div>
                                    <Link to={{pathname:"mailto:alex@tegdaffy.co.uk"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="hover:text-tegdaffy-red">alex@tegdaffy.co.uk</Link>
                                </div>
                            </div>
                        </div>

                        <div className="max-w-5xl mx-auto mt-12 sm:flex sm:space-x-10">
                            <div className="sm:w-1/3">

                                <div className="form-group">
                                    <label>
                                        Name
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <input type="text" name="name" className="form-input" value={contactForm.name} onChange={this.formChange} />
                                </div>
                                
                                <div className="form-group">
                                    <label>
                                        Email Address
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <input type="email" name="email" className="form-input" value={contactForm.email} onChange={this.formChange} />
                                </div>
                                
                                <div className="form-group">
                                    <label>
                                        Phone Number
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <input type="tel" name="phoneNumber" className="form-input" value={contactForm.phoneNumber} onChange={this.formChange} />
                                </div>
                                
                            </div>
                            <div className="sm:w-2/3">
                                <div className="form-group h-full">
                                    <label>
                                        Your Message
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <textarea className="form-input resize-none" name="message" placeholder="Send us a message..." style={{"height":"212px"}} value={contactForm.message} onChange={this.formChange}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="max-w-5xl mx-auto">
                            <button type="submit" className="btn btn btn-lg btn-block btn-tegdaffy-blue"  disabled={submitFormLoading}>
                                <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (submitFormLoading?"inline":"hidden")} />
                                Send Message
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        );
    }
}

export default Contact;
