import React, { Component } from "react";
import bg2 from "assets/images/bgs/bg-2.jpg";
import logo from "assets/images/icons/logo-colored.png";
import Testimony from "components/common/testimony.jsx";
import Contact from "components/common/contact.jsx";
import GettingStarted from "components/common/gettingStarted.jsx";


class CorporationTax extends Component {
    
    state = {};

    render() {
        
        return (
            <>

                <div className="bg-black relative">
                    <div className="h-36 container flex items-center relative z-20">
                        <div className="w-full text-white text-center text-3xl font-semibold">
                            Corporation Tax
                        </div>
                    </div>
                    <div className="w-full h-full opacity-40 img-bg3 absolute inset-0 z-10"></div>
                </div>


                <div className="container md:flex space-y-6 md:space-y-0 py-20">
                    <div className="md:w-1/2 md:flex">
                        <div className="md:my-auto">
                            <div className="text-lg md:text-2xl font-semibold uppercase">
                                What is
                            </div>
                            <div className="text-3xl md:text-5xl text-tegdaffy-red font-semibold">
                                Corporation Tax?
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2">
                        <div className="text-xl leading-10">
                            This is the tax due on profits made by UK resident and related entities overseas. A company is required by law to pay Corporation Tax on its profits. Our expert accountants will use your financial information to calculate your Corporation Tax liability and file your returns on your behalf.
                        </div>
                    </div>
                </div>
                

                <div className="md:flex bg-gray-100" style={{"minHeight":"500px"}}>
                    <div className="md:w-1/2 bg-black">
                        <img src={bg2} alt="Attention to detail" className="object-cover" />
                    </div>
                    <div className="md:w-1/2 md:flex">
                        <div className="md:my-auto mx-10 py-20 md:py-0">
                            <div className="text-tegdaffy-red text-2xl font-semibold">
                                How can TegDaffy help me with my corporation tax?
                            </div>
                            <div className="w-32 h-2 bg-tegdaffy-red my-10 rounded"></div>
                            <div className="text-xl leading-10">
                                Here at TegDaffy we can help you stay on top of your corporation tax. The first thing we will do is fulfil the legal requirement which is to get your company registered, you must be registered within 3 months of starting to trade as a fine may be applicable should you fail to register. 
                            </div>
                        </div>
                    </div>
                </div>


                <div className="py-20">
                    <div className="container">
                        <div className="text-tegdaffy-red text-2xl font-semibold">
                            Details
                        </div>

                        <div className="w-32 h-2 bg-tegdaffy-red my-6 rounded"></div>

                        <div className="text-xl leading-10">
                            Corporation Tax is payable before you file your company tax return.
                            <br />
                            <br />
                            The Corporation tax deadline is nine months and one day after the end of your accounting period for your previous financial year – so if your accounting period ends on 31 March, your Corporation Tax deadline is 1 January. 
                            <br />
                            <br />
                            Here at TegDaffy we will prepare your company tax return to work out how much Corporation Tax you are liable to pay – even though the deadline to file your company tax return is 12 months after the end of the accounting period it covers.
                        </div>
                    </div>
                </div>


                <div className="py-20 bg-gray-200">
                    <div className="container md:flex space-y-6 md:space-y-0">
                        <div className="md:w-1/2 md:flex">
                            <div className="md:my-auto">
                                <div className="px-5 py-3 inline-block bg-white rounded-lg">
                                    <img src={logo} alt="Tegdaffy Accountants" className="object-contain" />
                                </div>
                                <div className="mt-4 text-3xl md:text-4xl font-semibold uppercase">
                                    What can we do?
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2">
                            <div className="text-xl leading-10">
                                We can unpack the wide array of corporation tax allowances and reliefs available to you company such as:
                                <br />
                                <br />
                                <ul className="list-disc ml-5">
                                    <li>
                                        Deduct the costs of running your business 
                                    </li>
                                    <li>
                                        Deduct any necessary mileage
                                    </li>
                                    <li>
                                        Deduct any necessary accommodation 
                                    </li>
                                    <li>
                                        Deduct training 
                                    </li>
                                    <li>
                                        Research & Development relief – if you work on innovative projects in science and technology 
                                    </li>
                                    <li>
                                        Creative industry tax reliefs – allows companies in creative industries to claim large reductions 
                                    </li>
                                    <li>
                                        And many more 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                

                <Testimony />

                <Contact />

                <GettingStarted />


            </>
        );
    }
}

export default CorporationTax;
