import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Testimony extends Component {
    constructor(props) {
        super(props);
        this.cancelButtonRef = React.createRef();
    }

    state = {
        showModalNewUpload: false,
    };

    toggleModalNewUpload = (action) => {
        this.setState({showModalNewUpload: action});
    }

    componentDidMount() {
        // this.toggleModalNewUpload(true);
    }

    render() {
        const { showModalNewUpload } = this.state;

        return (
            <div>


                <Dialog as="div" open={showModalNewUpload} initialFocus={this.cancelButtonRef} onClose={() => this.toggleModalNewUpload(false)} className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                        <div className="w-full max-w-lg bg-white relative overflow-hidden">
                            <div className="h-12 px-3 bg-black flex justify-between border-b border-white border-opacity-20 overflow-hidden">
                                <div className="my-auto text-white">
                                    Tegdaffy Accountants
                                </div>
                                <div onClick={() => this.toggleModalNewUpload(false)} ref={this.cancelButtonRef} className="w-7 h-7 flex my-auto group hover:bg-red-400 hover:border-red-400 rounded-lg cursor-pointer">
                                    <FontAwesomeIcon icon="times" className="text-white m-auto" />
                                </div>
                            </div>
                            <div>
                                <div className="p-6">

                                    <div className="sm:max-w-3xl mx-auto mt-4 lg:mt-12 grid sm:grid-cols-2 gap-x-6 gap-y-2 box font-semibold">
                                        <div className="flex items-center space-x-4 border-r-2 border-transparent sm:border-gray-200 text-xl sm:text-2xl md:text-3xl">
                                            <FontAwesomeIcon icon="phone-square" className="text-tegdaffy-blue" />
                                            <div>
                                                <Link to={{pathname:"tel:+442032474747"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="hover:text-tegdaffy-red">+44 20 3247 4747</Link>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-4 text-xl sm:text-2xl md:text-3xl">
                                            <FontAwesomeIcon icon="envelope" className="text-tegdaffy-blue" />
                                            <div>
                                                <Link to={{pathname:"mailto:alex@tegdaffy.co.uk"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="hover:text-tegdaffy-red">alex@tegdaffy.co.uk</Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>


                <div className="mt-10 lg:mt-20 -mb-36 md:-mb-56 relative z-10">
                        <div className="container">
                            <div className="mx-2 lg:mx-10 xl:mx-44">
                                <div className="py-10 md:py-20 mx-auto bg-color-red flex bg-map-red bg-yellow-300 rounded-xl overflow-hidden">
                                    <div className="m-auto">
                                        <div className="px-4 whitespace-normal mt-4 text-white text-center text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-semibold xl:leading-7">
                                            Getting started is Easy
                                        </div>
                                        <div className="flex px-4 mt-6 xl:mt-10">
                                            <div className="m-auto grid sm:grid-cols-2 gap-x-0 md:gap-x-4 gap-y-2">
                                                <Link to={{pathname:"tel:+442032474747"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="btn btn btn-lg animate btn-white m-auto">
                                                    <FontAwesomeIcon icon="phone-alt" className="mr-2" />
                                                    +44 20 3247 4747
                                                </Link>
                                                <Link to={{pathname:"mailto:alex@tegdaffy.co.uk"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="btn btn btn-lg animate btn-white m-auto">
                                                    <FontAwesomeIcon icon="envelope" className="mr-2 relative -top-0.5" />
                                                    alex@tegdaffy.co.uk
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-36 md:h-56 bg-black"></div>
            </div>
        );
    }
}

export default Testimony;
