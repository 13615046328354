import React, { Component } from "react";
import logo from "assets/images/icons/logo-colored.png";
import Testimony from "components/common/testimony.jsx";
import Contact from "components/common/contact.jsx";
import GettingStarted from "components/common/gettingStarted.jsx";
import bg5 from "assets/images/bgs/bg-5.jpg";


class CorporationTax extends Component {
    
    state = {};

    render() {
        
        return (
            <>

                <div className="bg-black relative">
                    <div className="h-36 container flex items-center relative z-20">
                        <div className="w-full text-white text-center text-3xl font-semibold">
                            Personal Tax
                        </div>
                    </div>
                    <div className="w-full h-full opacity-40 img-bg3 absolute inset-0 z-10"></div>
                </div>



                <div className="container md:flex md:space-x-10 space-y-6 md:space-y-0 py-20">
                    <div className="md:w-1/2 md:flex">
                        <div className="md:my-auto">
                            <div className="text-lg md:text-2xl font-semibold uppercase">
                                What is
                            </div>
                            <div className="text-3xl md:text-5xl text-tegdaffy-red font-semibold">
                                Personal Tax?
                            </div>
                            <div className="mt-8 w-24 bg-tegdaffy-red h-2 rounded-full"></div>
                            <div className="mt-8 bg-tegdaffy-red bg-opacity-10 rounded-lg p-6 max-w-lg">
                                An individual(s) carrying on business as a sole trader or partnerships have a legal obligation to file Self-Assessment with HMRC; income from land and properties, savings and UK Company will also attract tax if certain thresholds are exceeded.
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2">
                        <div className="text-xl leading-10">
                            Here at TegDaffy we can help you understand how to manage your taxes quick and efficiently, these taxes can range from:
                            <ul className="list-disc ml-5">
                                <li>
                                    Income tax - the tax levied directly on personal income.
                                </li>
                                <li>
                                    Inheritance tax - the tax imposed on the estate of someone who's died, this includes any property, money and possessions. 
                                </li>
                                <li>
                                    Capital gains tax - the tax levied on the profit when you dispose of an asset that's increased in value.
                                </li>
                                <li>
                                    Trusts - A trust is a legal arrangement for managing assets. There are different types of trusts which are taxed differently.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                

                <div className="py-20 bg-gray-200">
                    <div className="container md:flex space-y-6 md:space-y-0 md:space-x-10">
                        <div className="md:w-1/2 md:flex">
                            <div className="md:my-auto">
                                <div className="px-5 py-3 inline-block bg-white rounded-lg">
                                    <img src={logo} alt="Tegdaffy Accountants" className="object-contain" />
                                </div>
                                <div className="mt-4 text-3xl md:text-4xl font-semibold uppercase">
                                    How can Tegdaffy help me with my tax returns?
                                </div>
                                <div className="text-xl text-tegdaffy-red mt-4 font-semibold">
                                    At TegDaffy we look to maximise your income, minimize your tax bill.
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2">
                            <div className="text-xl leading-10">
                                According to ICAEW 1.8 million people submitted their 2019/20 tax returns late and that figure is almost double from the year before. With the tax return process becoming digital it can be an arduous process, let our Qualified accountants at TegDaffy prepare your tax returns and submit them to HMRC on your behalf well ahead of time.
                            </div>
                        </div>
                    </div>
                </div>
                

                <div className="py-20">
                    <div className="container">
                        <div className="text-tegdaffy-red text-2xl font-semibold">
                            What is Tax relief? and how can I make the most of it?
                        </div>

                        <div className="w-32 h-2 bg-tegdaffy-red my-6 rounded"></div>

                        <div className="text-xl leading-10">
                            As well as explaining how your personal allowance affects how much tax you pay, we can also discuss tax liabilities so that you can benefit from any claims available. For example, did you know that if you are working from home, you are able to claim tax relief on expenses such as: metered water, gas and electricity and business phone calls?
                        </div>
                    </div>
                </div>


                <div className="md:flex bg-gray-100" style={{"minHeight":"500px"}}>
                    <div className="md:w-1/2 bg-black">
                        <img src={bg5} alt="Attention to detail" className="object-cover" />
                    </div>
                    <div className="md:w-1/2 md:flex">
                        <div className="md:my-auto mx-10 py-12 md:py-0">
                            <div className="text-tegdaffy-red text-2xl font-semibold">
                                When is personal tax due?
                            </div>
                            <div className="w-32 h-2 bg-tegdaffy-red my-10 rounded"></div>
                            <div className="text-xl leading-10">
                                Personal tax is due on midnight on the 31st of January the year after your tax year ends. For instance, tax accrued in the financial year up to 5th of April 2021 will need to be submitted before 31st of January 2022.
                            </div>
                        </div>
                    </div>
                </div>

                
                <Testimony />

                <Contact />

                <GettingStarted />



            </>
        );
    }
}

export default CorporationTax;
