import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css";

import 'assets/css/index.css';
import 'assets/css/tegdaffy.css';
// import 'assets/css/tegdaffyv1.css';
// import 'assets/css/tegdaffyv2.css';

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer autoClose={5000} />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);