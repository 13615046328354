import React, { Component } from "react";
import logo from "assets/images/icons/logo.png";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Header extends Component {
    state = {};

    render() {
        return (
            <>

                <div className="w-full fixed z-50 top-0 left-0 right-0">
                    <div className="h-14 lg:h-16 bg-black">
                        <div className="h-full flex container text-white">

                            <div className="flex-shrink-0 my-auto">
                                <Link to="/" className="h-14 py-2 flex 2xl:space-x-4 mr-4">
                                    <div className="hidden 2xl:block w-10 bg-white"></div>
                                    <img src={logo} alt="Tegdaffy Accountants" className="h-full object-contain my-auto" />
                                    <div className="hidden 2xl:block w-10 bg-white"></div>
                                </Link>
                            </div>

                            <div className="flex-grow hidden lg:flex uppercase" style={{"fontSize":"13px"}}>
                                <div className="flex flex-grow h-full">
                                    <div className="flex m-auto">
                                        <a href="/#" className="header-links">
                                            About Us
                                        </a>
                                        <a href="/#services" className="header-links">
                                            Services
                                        </a>
                                        <a href="/#newsletter" className="header-links">
                                            Newsletters
                                        </a>
                                        <a href="/#contact" className="header-links">
                                            Contact
                                        </a>
                                    </div>
                                </div>
                                <div className="flex-shrink-0 h-full flex">
                                    <div className="flex my-auto space-x-4">
                                        <Link to={{pathname:"tel:+442032474747"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="btn btn-md bg-tegdaffy-red text-white xl:text-md">
                                            +44 20 3247 4747
                                        </Link>
                                        <Link to={{pathname:"mailto:alex@tegdaffy.co.uk"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="btn btn-md bg-tegdaffy-blue text-white lowercase xl:text-md">
                                            alex@tegdaffy.co.uk
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-grow lg:hidden">
                                <Menu>
                                    <div className="w-full h-full flex justify-end">
                                        <Menu.Button as="button" className="w-10 h-10 my-auto text-white group hover:bg-white flex rounded cursor-pointer">
                                            <FontAwesomeIcon icon="bars" className="text-xl m-auto group-hover:text-black" />
                                        </Menu.Button>
                                    </div>
                                    <Menu.Items>
                                        <Menu.Item>
                                            <div className="fixed min-h-screen z-40 top-14 inset-x-0 bg-black text-white bg-opacity-90">
                                                <div className="h-screen py-20 overflow-auto overscroll-contain">
                                                    <div className="space-y-10 pb-14 text-center">
                                                        <div>
                                                            <a href="/#" className="header-links-mobile">
                                                                About Us
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a href="/#services" className="header-links-mobile">
                                                                Services
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a href="/#newsletter" className="header-links-mobile">
                                                                Newsletters
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a href="/#contact" className="header-links-mobile">
                                                                Contact
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <Link to={{pathname:"tel:+442032474747"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="text-xl mx-auto btn btn-lg bg-tegdaffy-red">
                                                                +44 20 3247 4747
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            <Link to={{pathname:"mailto:alex@tegdaffy.co.uk"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="text-xl mx-auto btn btn-lg bg-tegdaffy-blue">
                                                                alex@tegdaffy.co.uk
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Menu>
                            </div>

                        </div>
                    </div>
                    
                    <div className="w-full h-2 overflow-hidden" style={{"backgroundImage":"linear-gradient(to right, #0069a3 , #fc151e)"}}></div>
                </div>

                <div className="h-14 lg:h-16 mb-2"></div>

            </>
        );
    }
}

export default Header;
