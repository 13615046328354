import React, { Component } from "react";
import logo from "assets/images/icons/logo-colored.png";
import { Link } from "react-router-dom";
// import facebook from "assets/images/icons/facebook-fill.svg";
// import instagram from "assets/images/icons/instagram-fill.svg";
// import linkedin from "assets/images/icons/linkedin-fill.svg";

class Footer extends Component {
    state = {};

    render() {
        return (
            <>
                <div className="bg-black pt-14">
                    <div className="container text-white">

                        <div className="px-4 sm:px-0 text-center sm:text-left lg:flex">
                            <div className="lg:w-72 lg:flex-shrink-0">
                                <Link to="/" className="hidden text-2xl lg:text-3xl font-medium py-2 hover:text-tegdaffy-red">
                                    Tegdaffy.co.uk
                                </Link>
                                <Link to="/" className="px-5 py-3 inline-block bg-white rounded-lg">
                                    <img src={logo} alt="Tegdaffy Accountants" className="object-contain" />
                                </Link>
                                <div className="mt-4">
                                    <div className="font-light">
                                        Call Us: <Link to={{pathname:"tel:+442032474747"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="hover:text-tegdaffy-red">+44 20 3247 4747</Link>
                                    </div>
                                    <div className="font-light">
                                        Email Us: <Link to={{pathname:"mailto:alex@tegdaffy.co.uk"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="hover:text-tegdaffy-red">alex@tegdaffy.co.uk</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-tegdaffy-red h-1 my-7 lg:hidden opacity-20"></div>

                            <div className="lg:flex-grow grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-14">

                                <div>
                                    <div>
                                        <Link to="/#services" className="font-medium text-xl hover:text-tegdaffy-red">
                                            Our Services - Tax
                                        </Link>
                                    </div>
                                    <div className="space-y-4 mt-4">
                                        <div>
                                            <Link to="/personal-tax" className="font-light hover:text-tegdaffy-red">
                                                Personal Tax
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/corporation-tax" className="font-light hover:text-tegdaffy-red">
                                                Corporation Tax
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/capital-gains" className="font-light hover:text-tegdaffy-red">
                                                Capital Gains Tax
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/inheritance-tax" className="font-light hover:text-tegdaffy-red">
                                                Inheritance Tax
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/vat" className="font-light hover:text-tegdaffy-red">
                                                VAT
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/tax-advice" className="font-light hover:text-tegdaffy-red">
                                                Tax Advice
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                
                                <div>
                                    <div>
                                        <Link to="/#services" className="font-medium text-xl hover:text-tegdaffy-red">
                                            Our Services - Others
                                        </Link>
                                    </div>
                                    <div className="space-y-4 mt-4">
                                        <div>
                                            <Link to="/management-accounting" className="font-light hover:text-tegdaffy-red">
                                                Management Accounting
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/payroll" className="font-light hover:text-tegdaffy-red">
                                                Payroll
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/book-keeping" className="font-light hover:text-tegdaffy-red">
                                                Bookkeeping
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                
                                <div>
                                    <div>
                                        <Link to="#" className="font-medium text-xl hover:text-tegdaffy-red">
                                            Other Links
                                        </Link>
                                    </div>
                                    <div className="space-y-4 mt-4">
                                        <div>
                                            <a href="/#" className="font-light hover:text-tegdaffy-red">
                                                About Us
                                            </a>
                                        </div>
                                        <div>
                                            <a href="/#services" className="font-light hover:text-tegdaffy-red">
                                                Services
                                            </a>
                                        </div>
                                        <div>
                                            <a href="/#newsletter" className="font-light hover:text-tegdaffy-red">
                                                Newsletters
                                            </a>
                                        </div>
                                        <div>
                                            <a href="/#contact" className="font-light hover:text-tegdaffy-red">
                                                Contact Us
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div>
                                    <div>
                                        <Link to="#" className="font-medium text-xl hover:text-tegdaffy-red">
                                            Location
                                        </Link>
                                    </div>
                                    <div className="mt-4">
                                        <div>
                                            <a href="https://goo.gl/maps/kHC4aQZhjpSWyTHk7" target="_blank" rel="noreferrer" className="block font-light hover:text-tegdaffy-red">
                                                Dockyard Industrial Estate, Unit 6, Block 3 Woolwich, Woolwich Church St, Charlton, London SE18 5PQ
                                            </a>
                                        </div>
                                        <div className="mt-8">
                                            <div className="font-light">
                                                Find Us on Map: <a href="https://goo.gl/maps/kHC4aQZhjpSWyTHk7" target="_blank" rel="noreferrer" className="underline hover:text-tegdaffy-red">Open Google Maps</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className="pb-10"></div>

                    </div>
                </div>
            </>
        );
    }
}

export default Footer;
