import React, { Component } from "react";
import TypedReact from 'utils/typed.jsx';
import { Link } from "react-router-dom";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Testimony from "components/common/testimony.jsx";
import Contact from "components/common/contact.jsx";
import GettingStarted from "components/common/gettingStarted.jsx";
import homePics2 from "assets/images/bgs/homePics2.png";
import bg1 from "assets/images/bgs/bg-1.jpg";
import bg2 from "assets/images/bgs/bg-2.jpg";
import bg3 from "assets/images/bgs/bg-3.jpg";
import bg4 from "assets/images/bgs/bg-4.jpg";
import bg5 from "assets/images/bgs/bg-5.jpg";
import bg6 from "assets/images/bgs/bg-6.jpg";
import trophy from "assets/images/icons/trophy.svg";
import checkBadge from "assets/images/icons/check-badge.svg";
import salesHot from "assets/images/icons/sales-hot.svg";
import { ReactComponent as Shipping } from "assets/images/icons/shipping.svg";
import 'react-slideshow-image/dist/styles.css';


class Home extends Component {
    
    state = {};

    render() {
        customizeSVG();

        return (
            <>

                <div>

                    <div className="bg-black shadow-lg relative">
                        <div className="container md:flex py-20 md:py-10 relative z-20 text-white">

                            <div className="md:w-1/2 flex">
                                <div className="my-auto">
                                    <div className="font-semibold font-boing h-36 sm:h-28 md:h-32 lg:h-28 xl:h-32 text-4xl xl:text-5xl">
                                        <TypedReact
                                            strings={[
                                                'We go above and beyond to help your business grow',
                                                'We stay by you every step of the way',
                                            ]}
                                        />
                                    </div>

                                    <div className="text-base sm:text-lg md:leading-7 font-light opacity-70">
                                        Tegdaffy is a firm of certified chartered accountants based in Southeast London,
                                        which has been specializing in personalized taxation and accounting services since 2012.
                                    </div>
                                    <div className="mt-6 text-base sm:text-lg leading-7">
                                        Feel the Tegdaffy difference.
                                    </div>
                                    <div className="mt-6">
                                        <a href="/#services" className="btn bg-tegdaffy-red">
                                            Our Services
                                            <FontAwesomeIcon icon="angle-down" className="ml-2" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="md:w-1/2 hidden md:block">
                                <div className="flex justify-end" style={{"height":"500px"}}>
                                    <img src={homePics2} className="h-full object-contain" alt="Tegdaffy" />
                                </div>
                            </div>

                        </div>

                        <div className="w-full h-full opacity-40 img-bg3 absolute inset-0 z-10"></div>
                    </div>



                    <div id="services" className="pt-10 pb-20">
                        <div className="container">
                            <div className="text-center text-2xl md:text-4xl xl:text-5xl font-semibold mt-1 md:mt-4 md:space-y-3">
                                <div>
                                    Our Services
                                </div>
                            </div>

                            <div className="text-center sm:text-left grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 sm:gap-x-10 lg:gap-x-14 xl:gap-x-10 gap-y-8 sm:gap-y-10 lg:gap-y-14 mt-6 md:mt-12">

                                <Link to="/personal-tax" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg5} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        Personal Tax
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        The UK has the longest tax code in the world, so preparing even the most straightforward tax return can be complicated. There are various deadlines and responsibilities for taxpayers to be aware of.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/corporation-tax" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg2} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        Corporation Tax
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        Every limited company that is based in the UK is subject to corporation tax on their profits. Limited companies that are non-UK based can still be expected to pay corporation tax.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/capital-gains" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg3} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        Capital Gains Tax
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        Individuals are charged Capital Gains Tax in respect of gains made from selling, transferring or otherwise disposing of assets.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/inheritance-tax" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg3} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        Inheritance Tax
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        Inheritance Tax Planning is vital if you are looking to reduce the tax bill on your estate to help maximise the inheritance your loved ones will receive.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/management-accounting" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg3} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        Management Accounting
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        A complete range of services for all your business needs, including advanced monitoring, cash flow management and business advice.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/vat" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg3} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        VAT
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        Whether you're just starting out or have been in business for a long time, TegDaffy Accountants can help you meet your VAT responsibilities.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/payroll" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg3} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        Payroll
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        At TegDaffy Accountants we can offer you a full payroll service with plenty of options to suit you and your business.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/book-keeping" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg3} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        Bookkeeping
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        We provide accurate and comprehensive bookkeeping services to suit your needs. Everything you would expect from a professional bookkeeper.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/tax-advice" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg3} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        Tax Advice
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        Whether you have just started your business or have been in business for a long time, we help you with your tax planning needs.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/company-formation" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg3} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        Company Formation
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        We will help you form a company and depending on the nature of your business we will advise you on the appropriate code to choose.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/financial-accounts" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg3} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        Financial Accounts
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        A business final account is required by law to be submitted on an annual basis.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/business-plan" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg3} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        Business Planning and Advice
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        This document sets out the goals of the business and how to achieve them
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/hmrc-investigations" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg3} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        HMRC Investigations
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        We know and appreciate the stress and level of anxiety HMRC investigation can generate but the good news is that we are here to help you and your business each step of the way.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>

                                <Link to="/debt-management-credit-control" className="max-w-xs mx-auto block group border border-gray-300 rounded-2xl p-4 hover:shadow-2xl">
                                    <div className="h-32 mx-auto bg-black shadow-xl rounded-lg overflow-hidden">
                                        <img src={bg3} alt="Attention to detail" className="object-contain" />
                                    </div>
                                    <div className="font-medium text-lg sm:text-xl md:text-2xl mt-3 sm:mt-6 text-center">
                                        Debt Management (Credit Control)
                                    </div>
                                    <div className="sm:mt-2 text-md-lg md:leading-7 font-normal text-center text-fade">
                                        We will work with your debtors to ensure that monies due to your business are collected in a timely manner for a smoothly cash flow to run your business.
                                    </div>
                                    <div className="mt-2 flex">
                                        <button type="button" className="btn btn-md btn-transparent-black mx-auto group-hover:bg-tegdaffy-red group-hover:text-white group-hover:border-white">
                                            Get Started
                                        </button>
                                    </div>
                                </Link>



                            </div>
                        </div>
                    </div>



                    <div className="py-20 relative bg-gray-100">
                        <div id="features" className="container">
                            <div className="">

                                <div className="mb-16">
                                    <div className="text-2xl md:text-4xl xl:text-5xl font-semibold font-boing">
                                        Why you can trust us
                                    </div>
                                    <div className="font-light w-11/12 mt-4 sm:text-lg lg:text-xl">
                                        Here at TegDaffy we always put the client first. Our sole aim to&nbsp;
                                        <span className="font-semibold">
                                            maximise your income, and minimize your tax bill.
                                        </span>
                                    </div>
                                </div>

                                <div className="overflow-hidden grid sm:grid-cols-2 md:grid-cols-3 gap-4 xl:gap-8">

                                    <div className="h-full flex space-x-4 sm:space-x-0 sm:block p-8 xl:p-14 rounded-xl shadow-sm bg-white">
                                        <div className="w-16 flex-shrink-0">
                                            <img src={salesHot} alt="Improve Employee Productivity" className="sm:w-24 sm:h-24" />
                                        </div>
                                        <div className="sm:mt-6 flex-grow">
                                            <div className="text-xl lg:text-2xl font-semibold font-boing">
                                                Certified
                                            </div>
                                            <div className="mt-2 sm:mt-4">
                                                All of our accountants are trained and qualified to ACCA and CIMA standards, giving them the tools and know-how required to deal with any and all of your accountancy needs.
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="h-full flex space-x-4 sm:space-x-0 sm:block p-8 xl:p-14 rounded-xl shadow-sm bg-white">
                                        <div className="w-16 flex-shrink-0">
                                            <Shipping className="customizeSVG text-yellow-300 sm:w-20 sm:h-24" />
                                            {/* <img src={shipping} alt="Attract and Retain Best Talent" className="h-24 w-24" /> */}
                                        </div>
                                        <div className="sm:mt-6 flex-grow">
                                            <div className="text-xl lg:text-2xl font-semibold font-boing">
                                                Experienced
                                            </div>
                                            <div className="mt-2 sm:mt-4 font-light">
                                                We have operated at the very top of the industry standards for 10+ years, managing thousands of accounts from hundreds of business’ overseeing £XMillion+ in revenue. 
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="h-full flex space-x-4 sm:space-x-0 sm:block p-8 xl:p-14 rounded-xl shadow-sm bg-white">
                                        <div className="w-16 flex-shrink-0">
                                            <img src={checkBadge} alt="Build Financial Resilience" className="sm:w-24 sm:h-24" />
                                        </div>
                                        <div className="sm:mt-6 flex-grow">
                                            <div className="text-xl lg:text-2xl font-semibold font-boing">
                                                Reliable
                                            </div>
                                            <div className="mt-2 sm:mt-4 font-light">
                                                At TegDaffy we enjoy talking to business owners and self-employed professionals who are looking to get the most out of their accountant.
                                                You can visit our local office, arrange to meet via video call software,
                                                send us an email to&nbsp;
                                                <Link to={{pathname:"mailto:alex@tegdaffy.co.uk"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="underline">
                                                    alex@tegdaffy.co.uk
                                                </Link>
                                                &nbsp;or talk to us by telephone at&nbsp;
                                                <Link to={{pathname:"tel:02032474747"}} onClick={(e) => {window.location = e.target.getAttribute("href"); e.preventDefault(); }} className="underline">
                                                    020 3247 4747
                                                </Link>&nbsp;.
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>



                    <div id="newsletter" className="relative">

                        <div className="flex absolute z-0 w-full marketplace-gist-bg">
                            <div className="w-full" style={{"backgroundImage":"linear-gradient(to right, #0069a3 , #fc151e)"}}></div>
                        </div>

                        <div className="container relative z-10 lg:flex">
                            <div className="lg:w-4/5 flex-grow">
                                <div className="xl:flex xl:space-x-10 pt-20">
                                    <div className="xl:w-1/2 text-2xl sm:text-3xl md:text-5xl font-semibold text-white">
                                        Our Newsletters
                                    </div>
                                    <div className="xl:w-1/2 text-white text-lg xl:text-xl font-light mt-4 xl:leading-10">
                                        Here's why you need insights from reputable professionals
                                        about managing your finances and taxes.
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/5 flex-shrink-0 hidden lg:block"></div>
                        </div>

                        <div className="container relative z-10 mt-10 xl:mt-16 grid sm:grid-cols-2 md:grid-cols-3 gap-8 xl:gap-14">

                            <Link to="" className="bg-white block max-w-sm mx-auto rounded-lg p-2 md:p-1 lg:p-2 shadow-xl animate hover:scale-110">
                                <div className="w-full h-48 xl:h-64 bg-black rounded-lg overflow-hidden">
                                    <img src={bg1} alt="rectangle 1" className="object-cover w-full h-full" />
                                </div>
                                <div className="p-3 h-36 lg:h-40 xl:h-48">
                                    <div className="mt-1 lg:mt-2 xl:mt-5 text-tegdaffy-red uppercase text-xs lg:text-sm font-semibold tracking-widest">
                                        Payroll
                                    </div>
                                    <div className="font-semibold text-base lg:text-lg xl:text-xl mt-2 xl:mt-4 ellipsis-2-lines">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
                                    </div>
                                    <div className="flex mt-4 xl:mt-6 space-x-2">
                                        <div className="w-1/2 text-sm ellipsis text-tegdaffy-blue">
                                            Tegdaffy
                                        </div>
                                        <div className="w-1/2 text-sm text-right justify-end text-gray-400 ellipsis">
                                            Aug 30, 2021
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            
                            <Link to="" className="bg-white block max-w-sm mx-auto rounded-lg p-2 md:p-1 lg:p-2 shadow-xl animate hover:scale-110">
                                <div className="w-full h-48 xl:h-64 bg-black rounded-lg overflow-hidden">
                                    <img src={bg6} alt="rectangle 1" className="object-cover w-full h-full" />
                                </div>
                                <div className="p-3 h-36 lg:h-40 xl:h-48">
                                    <div className="mt-1 lg:mt-2 xl:mt-5 text-tegdaffy-red uppercase text-xs lg:text-sm font-semibold tracking-widest">
                                        Tax Advice
                                    </div>
                                    <div className="font-semibold text-base lg:text-lg xl:text-xl mt-2 xl:mt-4 ellipsis-2-lines">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
                                    </div>
                                    <div className="flex mt-4 xl:mt-6 space-x-2">
                                        <div className="w-1/2 text-sm ellipsis text-tegdaffy-blue">
                                            Tegdaffy
                                        </div>
                                        <div className="w-1/2 text-sm text-right justify-end text-gray-400 ellipsis">
                                            Aug 30, 2021
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            
                            <Link to="" className="bg-white block max-w-sm mx-auto rounded-lg p-2 md:p-1 lg:p-2 shadow-xl animate hover:scale-110">
                                <div className="w-full h-48 xl:h-64 bg-black rounded-lg overflow-hidden">
                                    <img src={bg4} alt="rectangle 1" className="object-cover w-full h-full" />
                                </div>
                                <div className="p-3 h-36 lg:h-40 xl:h-48">
                                    <div className="mt-1 lg:mt-2 xl:mt-5 text-tegdaffy-red uppercase text-xs lg:text-sm font-semibold tracking-widest">
                                        Personal Tax
                                    </div>
                                    <div className="font-semibold text-base lg:text-lg xl:text-xl mt-2 xl:mt-4 ellipsis-2-lines">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
                                    </div>
                                    <div className="flex mt-4 xl:mt-6 space-x-2">
                                        <div className="w-1/2 text-sm ellipsis text-tegdaffy-blue">
                                            Tegdaffy
                                        </div>
                                        <div className="w-1/2 text-sm text-right justify-end text-gray-400 ellipsis">
                                            Aug 30, 2021
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            
                        </div>
                        
                    </div>


                    <div className="pt-20">
                        <div className="container">

                            <div className="max-w-2xl text-center mx-auto">

                                <div className="text-center text-2xl md:text-3xl xl:text-4xl font-shadows text-tegdaffy-red">
                                    Achievements
                                </div>
                                <div className="text-center text-2xl md:text-4xl xl:text-5xl font-semibold mt-2 xl:mt-4">
                                    Here's a brief overview of how reputable we are.
                                </div>
                                <div className="flex mt-4 xl:mt-10">
                                    <img src={trophy} alt="trophy" className="w-10 h-10 xl:w-16 xl:h-16 object-contain m-auto" />
                                </div>
                            </div>

                        </div>


                        <div className="bg-map mt-8 pb-36">
                            <div className="container">

                                <div className="grid grid-cols-2 sm:grid-cols-none sm:flex sm:justify-around sm:items-end relative z-10 gap-y-6 sm:gap-y-0">
                                    <div className="w-40 h-40 sm:w-36 sm:h-32 mx-auto sm:mx-0 md:w-44 md:h-36 lg:w-52 lg:h-44 p-4 xl:w-72 xl:h-60 xl:p-8 bg-white shadow-xl rounded-xl flex animate hover:scale-110">
                                        <div className="m-auto text-center">
                                            <div className="text-2xl md:text-4xl xl:text-5xl font-semibold text-tegdaffy-red">
                                                8+
                                            </div>
                                            <div className="text-gray-500 text-sm md:text-base xl:text-lg leading-4 md:leading-6 mt-1 md:mt-2">
                                                Years of Operation
                                            </div>
                                        </div>
                                    </div>
                                        
                                    <div className="w-40 h-40 mx-auto sm:mx-0 md:w-60 md:h-48 lg:w-72 lg:h-60 p-4 xl:w-80 xl:h-72 xl:p-8 bg-white shadow-xl rounded-xl flex animate hover:scale-110">
                                        <div className="m-auto text-center">
                                            <div className="text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-semibold text-tegdaffy-red">
                                                £500M+
                                            </div>
                                            <div className="text-gray-500 text-sm sm:text-base xl:text-lg sm:leading-6 mt-1 sm:mt-2">
                                                Revenue managed over the years
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="w-40 h-40 sm:w-36 sm:h-32 mx-auto sm:mx-0 md:w-44 md:h-36 lg:w-52 lg:h-44 p-4 xl:w-72 xl:h-60 xl:p-8 bg-white shadow-xl rounded-xl flex animate hover:scale-110">
                                        <div className="m-auto text-center">
                                            <div className="text-2xl md:text-4xl xl:text-5xl font-semibold text-tegdaffy-red">
                                                10,000+
                                            </div>
                                            <div className="text-gray-500 text-sm md:text-base xl:text-lg leading-4 md:leading-6 mt-1 md:mt-2">
                                                Accounts managed over the years
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 sm:grid-cols-none sm:flex mt-6 sm:-mt-8 lg:-mt-14 xl:-mt-20 sm:mx-16 md:mx-24 xl:mx-48 sm:justify-around sm:items-end gap-y-6 sm:gap-y-0">
                                    <div className="w-40 h-40 mx-auto sm:mx-0 md:w-60 md:h-48 lg:w-72 lg:h-60 p-4 xl:w-80 xl:h-72 xl:p-8 bg-white shadow-xl rounded-xl flex animate hover:scale-110">
                                        <div className="m-auto text-center">
                                            <div className="text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-semibold text-tegdaffy-red">
                                                10+
                                            </div>
                                            <div className="text-gray-500 text-sm sm:text-base xl:text-lg sm:leading-6 mt-1 sm:mt-2">
                                                Certifications Acquired
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-40 h-40 mx-auto sm:mx-0 md:w-60 md:h-48 lg:w-72 lg:h-60 p-4 xl:w-80 xl:h-72 xl:p-8 bg-white shadow-xl rounded-xl flex animate hover:scale-110">
                                        <div className="m-auto text-center">
                                            <div className="text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-semibold text-tegdaffy-red">
                                                100+
                                            </div>
                                            <div className="text-gray-500 text-sm sm:text-base xl:text-lg sm:leading-6 mt-1 sm:mt-2">
                                                Businesses Supported
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>


                    <Testimony />

                    <Contact />

                    <GettingStarted />
                    

                </div>

            </>
        );
    }
}

export default Home;
