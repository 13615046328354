import React, { Component } from "react";
import Testimony from "components/common/testimony.jsx";
import Contact from "components/common/contact.jsx";
import GettingStarted from "components/common/gettingStarted.jsx";


class CorporationTax extends Component {
    
    state = {};

    render() {
        
        return (
            <>

                <div className="bg-black relative">
                    <div className="h-36 container flex items-center relative z-20">
                        <div className="w-full text-white text-center text-3xl font-semibold">
                            Value Added Tax
                        </div>
                    </div>
                    <div className="w-full h-full opacity-40 img-bg3 absolute inset-0 z-10"></div>
                </div>



                <div className="container md:flex md:space-x-10 space-y-6 md:space-y-0 py-20">
                    <div className="md:w-1/2 md:flex">
                        <div className="md:my-auto">
                            <div className="text-lg md:text-2xl font-semibold uppercase">
                                What is
                            </div>
                            <div className="text-3xl md:text-5xl text-tegdaffy-red font-semibold">
                                Value Added Tax?
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2">
                        <div className="text-xl leading-10">
                            Value Added Tax is a tax applied to goods and services purchased.
                            <br />
                            <br />
                            Our tax team will advise you if your turnover meets the threshold to register for VAT. Our team will calculate your VAT liability, provide advice on VAT planning and negotiate with HMRC on your behalf where necessary.
                            <br />
                            <br />
                            We would ensure that your returns are filed in timely manner in order to avoid unnecessary penalties. We will also advice your business is it is due a refund. We will support your business with MTD (Making Tax Digital) procedures, the new government initiative to automate VAT submission.
                        </div>
                    </div>
                </div>
                
                
                <Testimony />

                <Contact />

                <GettingStarted />

            </>
        );
    }
}

export default CorporationTax;
